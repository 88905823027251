// import Swiper , { Navigation } from 'swiper';
// Swiper.use([Navigation]);
// 單一一個
// new Swiper('.swiper-container', {


// 產品置中第一個 /$bottomSlide / $bottomSlideContent 打開
var $bottomSlide = null;
var $bottomSlideContent = null;

var swiperContainer = new Swiper(".swiper-product", {

    loop: true,

    slidesPerView: 1,
    spaceBetween: 0,
    paginationClickable: false,
    // 產品置中第一個
    centeredSlides: true,
    roundLengths: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});


var swiperContainer = new Swiper(".swiper-installation", {

    loop: true,

    slidesPerView: 1,
    spaceBetween: 0,
    paginationClickable: false,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});
