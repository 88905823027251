//  放影片的 div 要加 data-video-id="影片"
$(document).ready(function(){
  if($('#map').length){
    const LeafIcon = L.Icon.extend({
  		options: {
  			// shadowUrl: 'images/leaf-shadow.png',
  			iconSize:     [25, 41],
  			// shadowSize:   [50, 64],
  			iconAnchor:   [14, 40],
  			// shadowAnchor: [4, 62],
  			popupAnchor:  [-3, -20]
  		}
  	});
    const greenIcon = new LeafIcon({iconUrl: 'images/marker-icon.png'});


    const cities = L.layerGroup();
    const Taipei = L.marker([24.976674894153184, 121.51558793438666],{icon: greenIcon}).bindPopup('<h2>台北營業所</h2><a href="tel:+886-2-22115598"><span class="icon-call"></span>TEL:(02)22115598</a><p><span class="icon-fax"></span>FAX:(02)22115359</p><a href="mailto:a756330.sj@msa.hinet.net"><span class="icon-email"></span>E-mail:a756330.sj@msa.hinet.net</a><a href="https://www.google.com.tw/maps/place/%E6%96%B0%E5%8C%97%E5%B8%82%E6%96%B0%E5%BA%97%E5%8D%80%E5%AE%89%E8%88%88%E8%B7%AF105%E8%99%9F11%E6%A8%93%E4%B9%8B7/" target="_blank"><span class="icon-pin"></span>新北市新店區安興路105號11樓之7</a>').addTo(cities);
    const Taoyuan = L.marker([24.899265434439975, 121.13397391349187],{icon: greenIcon}).bindPopup('<h2>桃園分公司</h2><a href="tel:+886-3-4850208"><span class="icon-call"></span>TEL:(03)4850208</a><p><span class="icon-fax"></span>FAX:(03)4750177</p><a href="mailto:a756330@msa.hinet.net"><span class="icon-email"></span>E-mail:a756330@msa.hinet.net</a><a href="https://www.google.com.tw/maps/place/%E6%A1%83%E5%9C%92%E5%B8%82%E6%A5%8A%E6%A2%85%E5%8D%80%E7%89%B2%E7%89%B2%E8%B7%AF94%E5%B7%B739%E8%99%9F/" target="_blank"><span class="icon-pin"></span>桃園市楊梅區牲牲路94巷39號</a>').addTo(cities);
    const Miaoli = L.marker([24.486313211703397, 120.67561082376965],{icon: greenIcon}).bindPopup('<h2>苗栗總公司</h2><a href="tel:+886-37-756330"><span class="icon-call"></span>TEL:(037)756330</a><p><span class="icon-fax"></span>FAX:(037)758840</p><a href="mailto:a756330@msa.hinet.net"><span class="icon-email"></span>E-mail:a756330@msa.hinet.net</a><a href="https://www.google.com.tw/maps/place/%E8%8B%97%E6%A0%97%E7%B8%A3%E9%80%9A%E9%9C%84%E9%8E%AE%E9%80%9A%E5%8D%97%E9%87%8C%E9%80%9A%E5%8D%9791-1%E8%99%9F/" target="_blank"><span class="icon-pin"></span>苗栗縣通霄鎮通南里通南91-1號</a>').addTo(cities);
    const Taichung = L.marker([24.169458670827233, 120.64683971779098],{icon: greenIcon}).bindPopup('<h2>台中營業所</h2><a href="tel:+886-4-24524286"><span class="icon-call"></span>TEL:(04)24524286</a><a href="https://www.google.com.tw/maps/place/%E5%8F%B0%E4%B8%AD%E5%B8%82%E8%A5%BF%E5%B1%AF%E5%8D%80%E9%9D%92%E6%B5%B7%E8%B7%AF%E4%BA%8C%E6%AE%B5188%E8%99%9F16%E6%A8%93%E4%B9%8B5/" target="_blank"><span class="icon-pin"></span>台中市西屯區青海路二段188號16樓之5</a>').addTo(cities);
    const Changhua = L.marker([23.980639653829964, 120.64431188650815],{icon: greenIcon}).bindPopup('<h2>彰化分公司</h2><a href="tel:+886-49-2520235"><span class="icon-call"></span>TEL:(049)2520235</a><p><span class="icon-fax"></span>FAX:(049)2512718</p><a href="mailto:a756330@msa.hinet.net"><span class="icon-email"></span>E-mail:a756330@msa.hinet.net</a><a href="https://www.google.com.tw/maps/place/%E5%BD%B0%E5%8C%96%E7%B8%A3%E8%8A%AC%E5%9C%92%E9%84%89%E5%BD%B0%E5%8D%97%E8%B7%AF%E4%B8%80%E6%AE%B5639%E8%99%9F/" target="_blank"><span class="icon-pin"></span>彰化縣芬園鄉彰南路一段639號</a>').addTo(cities);
    const Chiayi = L.marker([23.504581344464896, 120.36235524232794],{icon: greenIcon}).bindPopup('<h2>嘉義分公司</h2><a href="tel:+886-5-2384988"><span class="icon-call"></span>TEL:(05)2384988</a><p><span class="icon-fax"></span>FAX:(05)2384983</p><a href="mailto:a756330@msa.hinet.net"><span class="icon-email"></span>E-mail:a756330@msa.hinet.net</a><a href="https://www.google.com.tw/maps/place/%E5%98%89%E7%BE%A9%E7%B8%A3%E5%A4%AA%E4%BF%9D%E5%B8%82%E5%8C%97%E6%B8%AF%E8%B7%AF%E4%BA%8C%E6%AE%B5661%E5%B7%B738%E8%99%9F/" target="_blank"><span class="icon-pin"></span>嘉義縣太保市北港路二段661巷38號</a>').addTo(cities);
    const osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    });



    const map = L.map('map', {
      center: [24.3802018043269, 121.0086921290561],
      zoom: 9,
      layers: [osm, cities]
    });
  }

});
